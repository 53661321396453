<template>
  <v-card elevation="6">
    <v-card-title
      ><h2
        class="my-3"
        style="text-transform: uppercase; font-weight: normal"
        v-text="$tc('studio')"
      ></h2>
      <v-btn
        icon
        v-if="$store.getters['auth/isLoggedIn']"
        style="position: absolute; right: 25px"
        @click="$router.push({ path: `/studios/${studio.id}/edit` })"
        small
      >
        <v-icon small color="primary"> $edit </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-simple-table>
      <tr>
        <td width="100px">{{ $t("first_name") }}</td>
        <td>{{ studio.studio_name }}</td>
      </tr>
      <tr>
        <td style="vertical-align: baseline">{{ $t("studios.address") }}</td>
        <td>
          <span v-if="studio.address">{{ studio.address }}, </span>
          <span v-if="studio.postal_code">{{ studio.postal_code }}, </span>
          <span v-if="studio.city">{{ studio.city }}, </span>
          <span v-if="studio.country">{{ country(studio.country) }}</span>
          <span
            v-if="
              !studio.address &&
              !studio.postal_code &&
              !studio.city &&
              !studio.country
            "
            >-</span
          >
        </td>
      </tr>

      <tr v-if="$store.getters['auth/isTattooer']">
        <td>{{ $t("phone") }}</td>
        <td>
          {{ studio.user.phone || "-" }}
        </td>
      </tr>
      <tr v-if="$store.getters['auth/isTattooer']">
        <td>{{ $t("correo") }}</td>
        <td>
          {{ studio.user.email || "-" }}
        </td>
      </tr>
      <tr v-if="$store.getters['auth/isTattooer']">
        <td style="vertical-align: baseline">{{ $t("studios.comment") }}</td>
        <td>{{ comment || "-" }}</td>
      </tr>

      <tr v-if="$store.getters['auth/isTattooer'] && commissions">
        <td>{{ $t("expenses.commissions") }}</td>
        <td>
          <v-btn
            class="ml-n1"
            @click="$refs[`commissions-${studio.id}`].dialog = true"
            icon
            x-small
          >
            <v-icon small>$eye</v-icon></v-btn
          >
          {{ com ? $n(com, "currency") : "-" }}
        </td>
      </tr>
      <commissions
        :disable="true"
        :tattooer="studio"
        :view="true"
        :list="true"
        :ref="`commissions-${studio.id}`"
      />
      <template v-if="show">
        <tr v-if="$store.getters['auth/isTattooer']">
          <td>{{ $t("studios.contact") }}</td>
          <td>
            {{ studio.user.first_name || "-" }}
            {{ studio.user.last_name }}
          </td>
        </tr>
        <tr v-if="$store.getters['auth/isTattooer']">
          <td>{{ $t("files") }}</td>
          <td>
            <v-btn class="ml-n1" x-small icon @click="goToFiles">
              <v-icon small>$eye</v-icon></v-btn
            >
          </td>
        </tr>

        <tr v-if="$store.getters['auth/isTattooer']">
          <td>{{ $t("vacations.title") }}</td>
          <td>
            <CloseDays :studio="studio"></CloseDays>
          </td>
        </tr>
      </template>
      <tr v-if="$store.getters['auth/isLoggedIn']">
        <td colspan="2">
          <a @click="show = !show">
            <u>{{ show ? $t("see_less") : $t("see_more") }}</u>
          </a>
        </td>
      </tr>
    </v-simple-table>
    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "StudioCard",
  props: ["studio", "com"],
  data() {
    return {
      countries: [],
      show: false,
    };
  },
  computed: {
    comment() {
      if (
        this.$store.getters["auth/isTattooer"] &&
        this.studio.tattooer_studios
      ) {
        return this.studio.tattooer_studios.find((q) => {
          return q.tattooer_id == this.$store.state.auth.user.tattooer.id;
        }).comment;
      }
    },
    commissions() {
      if (this.studio.tattooer_studios) {
        let commision = this.studio.tattooer_studios.find(
          (x) => x.tattooer_id == this.$store.state.auth.user.tattooer.id
        );
        if (commision.comisions) {
          return true;
        }
      }

      return false;
    },
  },
  components: {
    CloseDays: () => import("@/components/user/settings/profile/CloseDays"),
    Commissions: () => import("@/components/tattooer/Commissions"),
  },
  mounted() {
    this.loadCountry();
  },
  methods: {
    goToFiles() {
      let name = `${this.studio.studio_name}`;

      this.$router.push({
        name: "filesView",
        params: {
          sub_route: name,
        },
      });
    },
    loadCountry() {
      this.url = `https://restcountries.com/v2/all`;
      axios
        .get(this.url)
        .then((response) => {
          this.countries = response.data;
        })
        .catch((errorResponse) => {
          //console.log(errorResponse);
        });
    },
    country(code) {
      let country = this.countries.find((x) => x.alpha2Code === code);
      if (country)
        return (
          country.translations[this.$store.getters["app/language"]] ||
          country.name
        );
      //return code;
    },
  },
};
</script>



<style scoped lang="sass">
.v-data-table
  td
    padding: 3px 8px !important
  td:first-child
    color: var(--v-gris1-base)
h2
  font-weight: normal
  font-size: 20px
</style>